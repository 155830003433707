import React from 'react'
import LayoutFAQ from "../../components/layout/layoutFAQ";
import FaqSidebar from "../../components/FAQs/faqSidebar";
import {Sections} from "../../components/FAQs/faqData"
const Auction = () => {
  return <>
    <FaqSidebar selected={Sections.auction}/>
  </>
}

Auction.Layout = LayoutFAQ

export default Auction
